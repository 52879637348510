import { Stack } from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

export const Navigation: FC = () => {
  return (
    <Stack sx={{ m: 4 }} spacing={2}>
      <Link to="/shelf">Regal</Link>
      <Link to="/wardrobe">Garderobe</Link>
    </Stack>
  )
}
