import { createTheme } from '@mui/material'
import LehniFont from './core/assets/fonts/LehniAGSimonMono-Light.otf'
import TheinhardtFont from './core/assets/fonts/Theinhardt-Medium.otf'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    label?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#000000',
    },
  },
  typography: {
    fontFamily: 'Lehni',
    h1: {
      fontFamily: 'Theinhardt',
      fontSize: 24,
    },
    button: {
      fontSize: 16,
      fontWeight: 300,
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
    },
    body2: {
      fontSize: 9,
      fontWeight: 300,
    },
    label: {
      fontSize: 13,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lehni';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${LehniFont}) format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Theinhardt';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${TheinhardtFont}) format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        html {
          height: 100%;
        }
        body {
          height: 100%;
        },
        .draggable-item {
          maxWidth: 120px;
        }
      `,
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': {
            color: '#ff0000',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderTop: '1px solid #000000',
          borderRadius: 0,
          paddingTop: 0,
          flexGrow: 1,
          justifyContent: 'flex-start',
          paddingLeft: 0,
          fontSize: '16px',
          ':hover': {
            color: '#ff0000',
            borderColor: '#ff0000',
            backgroundColor: 'inherit',
          },
        },
        textPrimary: {
          color: '#00C200',
          borderColor: '#00C200',
          ':hover': {
            color: '#00C200',
            borderColor: '#00C200',
          },
          ':before': {
            backgroundColor: '#00c200',
            content: '""',
            height: '12px',
            left: 0,
            position: 'absolute',
            top: '-12px',
            transition: 'width .2s ease-in-out',
            width: 0,
            zIndex1: -1,
          },
          ':hover:before': {
            width: '100%',
          },
        },
        startIcon: {
          marginLeft: 0,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #7F7F7F',
          fontSize: '16px',
          fontWeight: 300,
          padding: 0,
          alignItems: 'flex-start',
          color: '#7F7F7F',
          '&.Mui-expanded': {
            color: '#000000',
            borderColor: '#000000',
            borderWidth: 10,
          },
          '&:hover': {
            color: '#ff0000',
            borderColor: '#ff0000',
          },
        },
        content: {
          margin: 0,
          marginTop: '4px',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 0 16px 0',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingBottom: '36px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'Theinhardt',
          fontSize: 24,
          borderBottom: 'solid 10px #000000',
          margin: '16px 24px',
          padding: '0 0 8px 0',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingRight: '24px',
          '.MuiButtonBase-root': {
            maxWidth: 200,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: '40px',
          height: '40px',
          '&.Mui-disabled': {
            '&>img': {
              opacity: 0.5,
            },
          },
        },
        sizeSmall: {
          width: '28px',
          height: '28px',
        },
      },
    },
  },
})
