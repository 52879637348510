import { render } from 'react-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from './theme'
import { LocalizationProvider, MessageBoxProvider } from '@teamsesam/core'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Navigation } from './Navigation'
import { FormValidationLocalization } from './core/components/FormValidationLocalization'
import { Translations } from './core/translations/translations'
import { de } from './core/translations/de'
import { en } from './core/translations/en'
import { rm } from './core/translations/rm'
import { isAsia } from './core/hooks/useIsAsia'
import { Currency } from './core/components/CurrencySelect'

const ShelfConigurator = React.lazy(() => import('./shelf/ShelfConfigurator'))
const WardrobeConigurator = React.lazy(() => import('./wardrobe/WardrobeConfigurator'))

const getLocale = () => {
  if (isAsia()) {
    return 'en'
  }
  const params = new URLSearchParams(window.location.search)
  const locale = params.get('locale')
  if (locale) {
    const pathParts = locale.split('/')
    if (pathParts.includes('en')) {
      return 'en-CH'
    }
  }
  return 'de-CH'
}

const getCurrency = (): Currency => {
  return isAsia() ? 'EUR' : 'CHF'
}

export type WindowWithLocale = Window & typeof globalThis & { locale: string; translations: Translations }

const getTranslations = (locale: string) => {
  switch (locale.substring(0, 2)) {
    case 'de':
      return de
    case 'en':
      return en
    case 'rm':
      return rm
  }
  throw new Error(`no translations found for locale '${locale}'`)
}

const getLocaleAndSetTranslationsIfChanged = () => {
  const locale = getLocale()
  const windowWithLocale = window as WindowWithLocale
  if (windowWithLocale.locale !== locale) {
    windowWithLocale.locale = locale
    windowWithLocale.translations = getTranslations(locale)
  }
  return locale
}

render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider locale={getLocaleAndSetTranslationsIfChanged()} currency={getCurrency()}>
      <MessageBoxProvider>
        <FormValidationLocalization />
        <CssBaseline />
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <Routes>
            <Route
              path="/"
              element={
                process.env.REACT_APP_REDIRECT_ROOT_TO_SHELF === 'true' ? (
                  <React.Suspense fallback={<>loading...</>}>
                    <ShelfConigurator />
                  </React.Suspense>
                ) : (
                  <Navigation />
                )
              }
            />
            <Route
              path="/wardrobe"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <WardrobeConigurator />
                </React.Suspense>
              }
            />
            <Route
              path="/shelf"
              element={
                <React.Suspense fallback={<>loading...</>}>
                  <ShelfConigurator />
                </React.Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </MessageBoxProvider>
    </LocalizationProvider>
  </ThemeProvider>,
  document.getElementById('app'),
)
