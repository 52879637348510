import {Translations} from './translations'

export const en : Translations = {
page: {
footer: {
save: 'Save',
partsList: 'Parts list',
download: {
label: 'Download',
withTexture: '3D with texture (.glb)',
withoutTexture: '3D without texture (.obj)',
},
exclVAT: 'excl. VAT',
orderRequest: 'Order request',
},
header: {
undo: 'Undo',
redo: 'Redo',
center: 'Centering',
closeDoors: 'Close doors',
openDoors: 'Open doors',
configure: 'Configure',
view3D: '3D view',
frontView: 'Front View',
switchToFrontView: 'switch to front view',
rearView: 'back view',
switchToRearView: 'Switch to back view',
},
items: {
construction: 'Structure',
depth: 'Depth',
withRolls: 'with Rolls',
withSideCovers: 'with Side cover',
withBackToBack: 'with back2back',
withFixedTopTray: 'with continuous top shelf ',
interior: 'Equipment',
front: 'Storage',
},
actions: {
addColumn: 'Add column',
moveColumnToLeft: 'Move column to the left',
duplicateColumn: 'Duplicate column',
deleteColumn: 'Delete column',
moveColumnToRight: 'Move column to the right',
changeColumnWidth: 'Change column width',
changeColumnHeight: 'Change column height',
},
selectedItem: {
color: 'Color',
applyToAll: 'Apply to all',
price: 'Unit price',
remove: 'Remove',
backWall: 'Back panel',
trayReinforcement: {
name: 'Reinforcement',
none: 'none',
upTo35kg: 'up to 35kg',
upTo100kg: 'up to 100kg',
},
withFeltMat: 'with felt mat',
withLinoleumCover: 'with Linoleum cover',
frontColor: 'Front color',
},
saveDesignDialog: {
title: 'Save design',
text: 'Receive a link to your configuration by email. This will allow you to continue editing your design at any time.',
cancel: 'Cancel',
save: 'Save design',
email: 'Email',
message: {
title: 'E-mail sent',
text: 'An email with a link to the configuration has been sent.',
},
},
partsListDialog: {
title: 'Parts list',
total: 'Total (excl. VAT)',
columns: {
partNr: 'Item No.',
description: 'Designation',
color: 'Color',
dimension: 'Dimension',
price: 'Price',
quantity: 'Quantity',
totalPrice: 'Total price',
},
subtotal: 'Subtotal',
additionalColorCost: 'Color surcharge',
},
orderDialog: {
title: 'Order request',
cancel: 'Cancel',
request: 'Request',
labels: {
name: 'First name, surname (contact person)',
company: 'Company',
section: 'Area',
private: 'Private',
retailer: 'Dealer',
architect: 'Architect',
email: 'E-mail',
address: 'Address',
zipCodeAndCity: 'Postcode City',
phone: 'Phone',
picking: 'Commission',
notes: 'Remarks',
},
message: {
title: 'Request sent',
text: 'An email with a link to the configuration has been sent.',
},
},
validationMessages: {
required: 'Input required',
email: 'Invalid e-mail address',
},
materialSelections: {
frameColor: 'Frame color',
accentColor: 'Front colour',
},
},
materials: {
Vernickelt: 'nickel-plated',
AluminiumBlank: 'blank',
AluminiumEloxiert: 'colorless anodized',
Brushed: 'brushed',
Zinkgelb: 'zinc yellow',
Currygelb: 'curry yellow',
Blutorange: 'blood orange',
Schwarzrot: 'black red',
Perlkupfer: 'pearl copper',
Tannengrün: 'fir green',
Perlweiss: 'pearl white',
Perlbeige: 'pearl beige',
Beigegrau: 'beige gray',
Graubraun: 'gray-brown',
Anthrazit: 'anthracite',
Tiefschwarz: 'black (deep black)',
Signalweiss: 'white (signal white)',
Schwarz: 'linoleum black',
Pebble: 'linoleum pebble',
Eiche: 'wood veneer oak',
Multiplex: 'multiplex',
Chrome: 'chrome',
FeltAnthrazit: 'anthracite',
BlechVerzinkt: 'galvanized',
BlechBlumigVerzinkt: 'flowered galvanized',
WeissAluminium: 'white aluminum',
LehniBlau: 'lehni blue',
Mirror: 'mirror',
Net: 'net',
},
articles: {
shelf: {
SideWall: 'Side unite',
Foot: 'Foot part',
StabilityCross: 'Cross brace',
Tray: 'Shelf',
Bookend: 'Bookend',
ColumnBackWall: 'Full back panel',
PullOutTray: 'Pull-out shelf',
InnerTray: 'Intermediate shelf to sliding / slide-in door',
DiagonalTray: 'Inclined shelf',
SlideInDoor: 'Slide-in door',
SlidingDoor: 'Sliding door',
DoorBackWall: 'Single back panel',
Drawer: 'Drawer',
SideCover: 'Cover side panel',
ConnectingSleeve: 'Connecting sleeve',
TableBoard: 'Desktop',
ClothesRail: 'Clothes rail',
TrayEnforcment: 'Shelf reinforcement',
FeltMat: 'Felt mat',
SideWallTopCover: 'Cover bar',
RollsFrame: 'Frame with swivel castors',
PreconfiguredShelf: 'Aluminum shelf',
FixedTopTray: 'Top shelf for back to back shelves',
LinoleumCover: 'Linoleum Cover',
FixedTopTrayLinoleumCover: 'Linoleum Cover for back to back shelves',
},
wardrobe: {
ShoeLocker10: {
name: 'Shoe cabinet with flap doors',
variant: '10 Flap doors',
},
ShoeLocker5: {
name: 'Shoe cabinet with flap doors',
variant: '5 Flap doors',
},
ShoeLocker4: {
name: 'Shoe cabinet with flap doors',
variant: '4 Flap doors',
},
MirrorCabinetNets: {
name: 'Mirror cabinet',
variant: '1 Shelf, 4 Nylon nets black',
},
MirrorCabinetTrays: {
name: 'Mirror cabinet',
variant: '7 Shelves',
},
NetContainer: {
name: 'Net / Shelf container',
variant: '5 Nylon nets black',
},
TrayContainer: {
name: 'Net / Shelf container',
variant: '7 Shelves',
},
FoldingWardrobe: {
name: 'Wardrobe foldable',
},
},
},
mail: {
order: {
subject: 'Lehni quote request product:',
requestText: 'Thank you for your inquiry. Below you will find a dimensioned sketch and the parts list of the individual parts used. We will answer your questions as soon as possible.',
lehniText: 'Distribution takes place via our specialized trade partners <a href="https://lehni.ch/">lehni.ch</a>.',
linkText: {
text: '{link} you can look at your configuration again.',
linkLabel: 'Here',
},
contact: 'Contact details',
labels: {
name: 'Name',
company: 'Company',
section: 'Client type',
email: 'E-mail',
address: 'Address',
zipCodeAndCity: 'Zip code City',
phone: 'Phone',
picking: 'Commission',
notes: 'Remarks',
},
},
partsList: {
title: 'Parts list',
depth: 'Shelf depth',
columns: {
partNr: 'Article No.',
description: 'Designation',
color: 'Color',
dimension: 'Size',
price: 'Price',
quantity: 'PCS',
totalPrice: 'Total price',
},
subtotal: 'Subtotal',
additionalColorCost: 'Surcharge color',
total: 'Total (excl. VAT)',
dimension: 'Dimensions',
},
saveDesign: {
subject: 'Your saved Lehni product:',
text: 'Thank you for your interest in a Lehni product. {link} you can view and edit your configuration.',
linkLabel: 'Here',
},
},
pdf: {
linkText: {
text: 'The configuration {commissionNr} is {link} saved',
linkLabel: 'here',
},
partsList: {
title: 'Parts list',
depth: 'Shelf depth',
columns: {
partNr: 'Item No.',
description: 'Designation',
color: 'Color',
dimension: 'Size',
price: 'Price',
quantity: 'PCS',
totalPrice: 'Total price',
},
subtotal: 'Subtotal',
additionalColorCost: 'Surcharge color',
total: 'Total (excl. VAT)',
}
}
}
