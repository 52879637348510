import { useEffect } from 'react'
import * as yup from 'yup'
import { useTexts } from '../translations/useTexts'

export const useYupLocale = () => {
  const texts = useTexts().page.validationMessages
  useEffect(() => {
    yup.setLocale({
      mixed: {
        required: texts.required,
      },
      string: {
        email: texts.email,
      },
    })
  }, [texts.email, texts.required])
}
