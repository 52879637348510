import { useMemo } from 'react'

export const isAsia = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return timeZone.startsWith('Asia')
}

export const useIsAsia = (): boolean => {
  return useMemo<boolean>(() => isAsia(), [])
}
